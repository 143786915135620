<template>
  <div class="container">
    <a name="top"></a>
    <div id="header">CODECLAVE</div>
    <div id="navtop-container">
      <span v-if="user" class="fr pd-10px">Welcome {{user}}!</span>
      <ul id="navtop">
        <li><a href="/web/home">Home</a></li>
        <li><a href="/web/leetcode">Leetcode</a></li>
        <li><a href="/web/algorithm">Classical Algorithms</a></li>
        <li><a href="/web/softwareengineering">Software Engineering</a></li>
        <li><a href="#">Buy me a coffee</a></li>
        <li v-if="!user"><a @click="modalData.loginModal.show=true;" href="#">Login</a></li>
        <li v-if="!user"><a @click="modalData.signInModal.show=true;" href="#">Sign up</a></li>
        <li v-if="user"><a @click="logout" href="#">Logout</a></li>
      </ul>
    </div>
    <div id="maincontainer">
      <div id="contentwrapper">
        <router-view />
      </div>
      <div id="footer"> All contents &copy; Copyright 2021 <a href="https://codeclave.com">codeclave.com</a><br />
        Design by wfiedler<br />
        <a href="https://validator.w3.org/check?uri=referer">Valid XHTML 1.0 Transitional</a>
        <p align="right"><a href="#top">top ´|`</a></p>
      </div>
    </div>
  </div>
  <!--Modals-->
  <login-modal-view v-if="modalData.loginModal.show" @showHideLoginModal="showHideModal"></login-modal-view>
  <sign-in-modal-view v-if="modalData.signInModal.show" @showHideSignInModal="showHideModal"></sign-in-modal-view>

</template>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

body {
  margin: 0;
  padding: 0;
  line-height: 1.5em;
  background: #fff;
  font: 76%/160% verdana, arial, helvetica, sans-serif;
  color: #000;
  letter-spacing: 1px;
}

#header {
  min-width: 600px !important;
  background-color: #f5f5dc;
  padding-top: 20px;
  font-size: 2.4em;
  font-weight: normal;
  letter-spacing: 20px;
  height: 40px;
  text-align: center;
  border-bottom: 1px solid #000;
  width: 100vw !important;
}

ul#navtop {
  background-color: #f5f5dc;
  margin: 0;
  padding: 10px 0 10px 30px;
  white-space: nowrap;
}

#navtop-container {
  min-width: 600px !important;
  width: 100vw;
  background-color: #f5f5dc;
  ;
  border-bottom: 1px solid #000;
}

/*Navigation Styles*/

#navtop li {
  display: inline;
  list-style-type: none;
}

#navtop a {
  padding: 3px 10px;
}

#navtop a:link,
#navtop a:visited {
  color: #000;
  text-decoration: none;
  border: 1px solid #f5f5dc;
  outline: none;
}

#navtop a:hover {
  color: #000;
  background-color: #f9f9ea;
  text-decoration: none;
  border: 1px solid #000;
}

.navbottom {
  margin-left: 0;
  padding-left: 0;
}

#maincontainer {
  width: 600px;
  margin: 50px auto;
}

#contentwrapper {
  float: left;
  width: 100%;
}

/*Content Styles*/

#contentcolumn {
  font-size: 1em;
}

a,
a:active,
a:visited {
  outline: none;
  color: #626258;
  text-decoration: underline;
}

a:hover {
  color: #939384;
  text-decoration: none;
}

h1,
h2,
h3 {
  font-size: 1.3em;
  margin: 0;
  color: #626258;
}

h4,
h5,
h6 {
  font-size: 1.1em;
  margin: 0;
  color: #626258
}

#list {
  margin-left: 20px;
  padding-left: 0;
  list-style: none;
}

#list li {
  padding-left: 10px;
  background: url(assets/images/list.gif) no-repeat 0 7px;
}

div.hr {
  margin: 20px 0 20px 0;
  height: 10px;
  background: #fff url(assets/images/hr.gif);
}

div.hr hr {
  display: none;
}

#columns {
  margin: 0;
}

#columns li {
  list-style-type: none;
}

#leftcolumn {
  float: left;
  width: 48%;
  font-size: 1em;
}

#leftcolumn li {
  padding-left: 15px;
  background: url(assets/images/link.gif) no-repeat 0 5px;
}

#rightcolumn {
  float: right;
  width: 48%;
  font-size: 1em;
}

#rightcolumn li {
  padding-left: 15px;
  background: url(assets/images/link.gif) no-repeat 0 5px;
}

#footer {
  clear: left;
  width: 100%;
  border-top: 40px solid #fff;
  border-bottom: 20px solid #fff;
  font-size: 0.9em;
}

/*Image and Gallery Styles*/

.imgleft {
  float: left;
  margin: 6px 10px 0 0;
}

.imgright {
  float: right;
  margin: 6px 0 0 10px;
}

.gallery {
  margin-right: 5px;
  border: none;
}

.gallery_right_img {
  margin-right: 0;
  border: none;
}

/*Form Styles*/

.form {
  margin: 20px 0px 20px 0px;
}

.form p {
  width: 300px;
  clear: left;
  margin: 0;
  padding: 5px 0 8px 0;
  padding-left: 155px;
  height: 1%;
}

.form label {
  font-weight: bold;
  float: left;
  margin-left: -155px;
  width: 150px;
}

.form .input {
  width: 275px;
  background-color: #f5f5dc;
  border: 1px solid #f5f5dc;
  letter-spacing: 1px;
  padding: 4px;
}

.form textarea {
  width: 275px;
  height: 150px;
  background-color: #f5f5dc;
  border: 1px solid #f5f5dc;
  font: 1em/160% verdana, arial, helvetica, sans-serif;
  letter-spacing: 1px;
  padding: 4px;
}

#submit {
  margin-left: 155px;
}

* html #submit {
  margin-left: 158px;
}

.submitbutton {
  border: 1px solid #939384;
  background: #f5f5dc url(assets/images/button.gif);
  letter-spacing: 1px;
  width: 140px;
}

.crossbutton {
  border: 1px solid #939384;
  background: #f5f5dc url(assets/images/button.gif);
  letter-spacing: 1px;
}

.pd-10px {
  padding: 10px;
}

.position-absolute {
  z-index: 110;
  ;
}

@media only screen and (max-width: 1024px) {
  ul#navtop {
    display: inline-grid;
  }
}

.fr {
  float: right;
}

.tc {
  text-align: center;
}

.cp {
  cursor: pointer;
}

blockquote {
  background-color: #D1D1D0;
  font-family: 'Monaco', monospace;
  padding: 0 1em;
}
</style>

<script>
import LoginModalView from './components/LoginModalView';
import SignInModalView from './components/SignInModalView';
export default {
  components: {
    LoginModalView,
    SignInModalView
  },
  data() {
    return {
      modalData: {
        "loginModal": {
          "show": false
        },
        "signInModal": {
          "show": false
        }
      },
      user: function () {
        if (!localStorage.getItem("jwt")) {
          return null;
        }
        let base64Url = localStorage.getItem("jwt").split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let payload = JSON.parse(JSON.parse(jsonPayload)["sub"]);
        return payload["firstName"];
      }()
    }
  },
  methods: {
    showHideModal(modalName, show) {
      this.modalData[modalName].show = show;
    },
    logout() {
      localStorage.clear();
      location.reload();
    }
  }
}
</script>
