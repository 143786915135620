import axios from 'axios';
export default class UserService {
    getOrigin() {
        return window.location.protocol + "//" + window.location.host;
    }
    getRecentlyAddedBlogs(no) {
        return axios.get(this.getOrigin() + "/blogquery/query/api/v1/preview?_class=com.codeclave.blogquery.entity.Preview&sortBy=createdDate&sortOrder=desc&pageNumber=0&pageSize="+no);
    }

    getRecentlyAddedBlogsByType(no, type) {
        return axios.get(this.getOrigin() + "/blogquery/query/api/v1/preview?type=" + type + "&sortBy=createdDate&sortOrder=desc&pageSize=10&pageNumber=" + no);
    }
}