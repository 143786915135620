import axios from 'axios';
export default class UserService {
    getOrigin() {
        return window.location.protocol + "//" + window.location.host;
    }
    login(loginForm) {
        return axios.post(this.getOrigin() + "/auth/api/v1/jwt", loginForm);
    }

    signin(signInForm) {
        return axios.post(this.getOrigin() + "/auth/api/v1/register", signInForm);
    }
}