<template>
    <div class='modal-overlay'></div>
    <div class="modal-md">
        <div id="submit">
            <button class="crossbutton fr position-absolute" @click="showHideModal(false)">x</button>
        </div>
        <div class="modal-content">
            <div id="myform" class="form">
                <h1>Sign Up</h1>
                <p>
                    <label for="user">Username</label>
                    <input v-model="signInForm.userName" class="input" type="text" id="user" />
                </p>
                <p>
                    <label for="email">Password:</label>
                    <input v-model="signInForm.password" class="input" type="password" id="password" />
                </p>
                <p>
                    <label for="email">Re type Password:</label>
                    <input v-model="repassword" class="input" type="password" id="password" />
                </p>
                <p>
                    <label for="email">Email:</label>
                    <input v-model="signInForm.email" class="input" type="text" id="email" />
                </p>
                <p>
                    <label for="email">First Name:</label>
                    <input v-model="signInForm.firstName" class="input" type="text" id="firstname" />
                </p>

                <p>
                    <label for="email">Last name:</label>
                    <input v-model="signInForm.lastName" class="input" type="text" id="lastName" />
                </p>

                <div id="submit">
                    <div class="form-feedback">{{errorMessage}}</div>
                    <button
                        :disabled="signInForm.userName == '' || signInForm.password == '' || signInForm.email == '' || signInForm.firstName == '' || signInForm.lastName == ''"
                        class="submitbutton" @click="signIn">Sign In</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100vw;
    height: 100vh;
    background: black;
    background: rgba(0, 0, 0, 0.8);
    filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    -moz-filter: blur(4px);
    -webkit-filter: blur(4px);
    z-index: 10;
}

.modal-md {
    position: absolute;
    padding: 15px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: white;
    z-index: 11;
}

.modal-content {
    position: relative;
}

.form-feedback {
    color: red;
    font-style: italic;
    font-size: 70%;
}

.form-feedback-success {
    color: green;
    font-style: italic;
    font-size: 70%;
}
</style>
<script>
import UserService from '../services/UserService';
export default {
    name: 'SignInModalView',
    data() {
        return {
            showForm: false,
            repassword: "",
            signInForm: {
                userName: "",
                password: "",
                email: "",
                firstName: "",
                lastName: ""
            },
            errorMessage: ""
        }
    },
    methods: {
        showHideModal(show) {
            this.$emit("showHideSignInModal", "signInModal", show);
        },

        signIn() {
            this.errorMessage = "";
            if (this.repassword != this.signInForm.password) {
                this.errorMessage = "Passwords are not same.";
                return;
            }

            if (!this.signInForm.email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                this.errorMessage = "Email is invalid.";
                return;
            }

            let signInPromise = new UserService().signin(this.signInForm);
            signInPromise.then(() => {
                this.login(this.signInForm);
            }, err => this.errorMessage = err.response.data.message);
        },

        login(loginData) {
            this.errorMessage = "";
            let loginPromise = new UserService().login(loginData);
            loginPromise.then(data => {
                localStorage.setItem("jwt", data.data['jwt']);
                location.reload();
            }, err => this.errorMessage = err.response.data.message);
        }
    }
}
</script>