<template>
    <div class='modal-overlay'></div>
    <div class="modal-md">
        <div id="submit">
            <button class="crossbutton fr" @click="showHideModal(false)">x</button>
        </div>
        <div class="modal-content">
            <div id="myform" class="form">
                <h1>Login</h1>
                <p>
                    <label for="user">Username</label>
                    <input v-model="loginForm.userName" class="input" type="text" id="user" />
                </p>
                <p>
                    <label for="email">Password:</label>
                    <input type="password" v-model="loginForm.password" class="input" id="password" />
                </p>

                <div id="submit">
                    <div class="form-feedback">{{errorMessage}}</div>
                    <button :disabled="loginForm.userName == '' || loginForm.password == ''" class="submitbutton"
                        @click="login">Login</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100vw;
    height: 100vh;
    background: black;
    background: rgba(0, 0, 0, 0.8);
    filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    -moz-filter: blur(4px);
    -webkit-filter: blur(4px);
    z-index: 10;
}

.modal-md {
    position: absolute;
    padding: 15px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: white;
    z-index: 11;
}

.modal-content {
    position: relative;
}

.form-feedback {
    color: red;
    font-style: italic;
    font-size: 70%;
}
</style>
<script>
import UserService from '../services/UserService';
export default {
    name: 'LoginModalView',
    data() {
        return {
            loginForm: {
                userName: "",
                password: ""
            },
            errorMessage: ""
        }
    },
    methods: {
        showHideModal(show) {
            this.$emit("showHideLoginModal", "loginModal", show);
        },

        login() {
            this.errorMessage = "";
            let loginPromise = new UserService().login(this.loginForm);
            loginPromise.then(data => {
                localStorage.setItem("jwt", data.data['jwt']);
                location.reload();
            }, err => this.errorMessage = err.response.data.message);
        }
    }
}
</script>