<template>
  <div class="page-content">
    <div id="contentcolumn">
      <h1>The Intent</h1>
      <img class="imgleft" src="@/assets/images/algo.gif" width="112" height="112" alt="" /> Building a good software
      requires a grasp of basic engineering concepts. This site is intended for software engineers to
      remain in touch with classical software engineering while learning and discussing about new advancement in
      software industry. Simplicity in design of architecture is one of the key to write a good and reliable
      software. This algorithm section is aimed to keep beginners updated about basics and to improve their understandings with no-fluff articles.
      <p> <img src="@/assets/images/more.gif" width="9" height="9" alt="Read more!" /> <a
          href="/web/algorithm">Classical algorithms...</a> </p>
      <p> <img class="imgright" src="@/assets/images/dev.png" width="112" height="112" alt="" /> Present day software
        engineering has evolved to cater to needs of our increasingly complex-becoming use cases. These new
        advancement are new opportunities to explore. I will write on this website about patterns and practices based
        on recent developments in software technology. Lets keep learning techniques and building the tools to do it
        effectively. </p>
      <p> <img src="@/assets/images/more.gif" width="9" height="9" alt="Read more!" /> <a
          href="/web/softwareengineering"> Follow new advancements ...</a> </p>
      <div class="hr">
        <hr />
      </div>
      <h4>About me</h4>
      I am a learner who is trying to journal a diary of everyday learning experience. I like writing technology blogs
      and playing video games. For this website I will go by name of codeclave. You can reach out to me using the
      contact for here or through the comments in the posts. <a target="_blank" href="https://drive.google.com/file/d/1cDBODQSRi1kVaROhHCbrFb2PmM37jb-M/view?usp=sharing"> Click to download my resume!  </a> 
      <div class="hr">
        <hr />
      </div>
      <h4>Latest Feeds</h4>
      <ul id="list">
        <li class="cp" v-for="blog in titles" :key="blog.title"><a :href=blogURL(blog.title)>{{ blog.title }}</a></li>
      </ul>
      <div class="hr">
        <hr />
      </div>
      <h4>Write to me</h4>
      <form id="myform" class="form" action="#">
        <p>
          <label for="user">Name</label>
          <input class="input" type="text" id="user" value="" />
        </p>
        <p>
          <label for="email">E-mail:</label>
          <input class="input" type="text" id="email" value="" />
        </p>
        <p>
          <label for="message">Message:</label>
          <textarea id="message" rows="5" cols="25"></textarea>
        </p>
        <div id="submit">
          <input class="submitbutton" type="submit" value="Submit Message" />
          <input class="submitbutton" type="reset" value="Clear Form" />
        </div>
      </form>
    </div>
    <div class="hr">
      <hr />
    </div>
    <!-- <h4>Featured Contributors</h4>
    <div id="columns">
      <div id="leftcolumn">
        <ul class="navbottom">
          <li><a>Anonymous 1</a></li>
          <li><a>Anonymous 2</a></li>
          <li><a>Anonymous 3</a></li>
          <li><a>Anonymous 4</a></li>
          <li><a>Anonymous 5</a></li>
        </ul>
      </div>
      <div id="rightcolumn">
        <ul class="navbottom">
          <li><a>Anonymous 6</a></li>
          <li><a>Anonymous 7</a></li>
          <li><a>Anonymous 8</a></li>
          <li><a>Anonymous 9</a></li>
          <li><a>Anonymous 10</a></li>
        </ul>
      </div>
    </div> -->
  </div>

</template>

<script>
import PreviewService from '../services/PreviewService';
// @ is an alias to /src
export default {
    name: "HomeView",
    mounted: function (){
      new PreviewService().getRecentlyAddedBlogs(5)
          .then(data => this.titles = data.data.blogs);
    },
    data() {
      return { "titles": [] }
    },
    methods: {
      blogURL(uri) { return "https://codeclave.com/web/title/" + uri.replace(" ", "_")}
    }
}
</script>
