export default class JWTUserInfoService {
    fetchUserDetails() {
        let jsonPayload = this.getTokenDetails();
        return JSON.parse(jsonPayload["sub"]);
    }

    getTokenDetails() {
        let base64Url = localStorage.getItem("jwt").split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        return JSON.parse(jsonPayload);
    }

    isAuthenticated() {
        let jsonPayload = this.getTokenDetails();
        return jsonPayload.iss == 'https://codeclave.com' && jsonPayload.exp > 0  && this.fetchUserDetails() != null;
    } 
}